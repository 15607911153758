<!--
 * @Description: 产品详情-产品概览
 * @Author: kecraft
 * @Date: 2024-01-04 14:11:18
 * @LastEditors: kecraft
 * @LastEditTime: 2024-05-21 15:11:20
 * @FilePath: /impact-iotos-console/src/views/device/device/detail/overview.vue
-->
<template>
  <div class="product-overview">
    <div>
      <div class="overview-item">
        <div class="overview-group">
          <div class="overview-left">
            <div class="overview-icon">
              <img src="@/assets/login/product.png" alt="">
            </div>
            <div class="overview-title">设备信息</div>
          </div>
          <!-- <div class="edit-btn" @click="gotoEdit">编辑产品信息</div> -->
        </div>
      </div>
      <div class="pk-conatiner">
        <div class="overview-pk" v-if="!isEdit">设备名称：{{ form.name || "-" }}</div>
        <div class="overview-pk" v-else>
          <span>设备名称：</span> <el-input v-model="form.name" style="width:200px" placeholder="请输入设备名称" />
        </div>
        <div class="edit1-btn" @click="() => isEdit = true" v-if="!isEdit">编辑</div>
        <div class="edit1-btn" @click="edit" v-else>确认</div>
      </div>
    </div>

    <!-- 基本信息 -->
    <div class="info-container">
      <div class="info-item first-left ">
        <div class="item-left">设备ID</div>
        <div class="item-right">
          <span>{{ form && form.devId || "--" }}</span>
          <span class="copy-btn" style="display:inline-block" @click="copyToClipboard(form.devId)">复制</span>
        </div>
        <!-- <div>{{ form && form.devId || "--" }}</div> -->
      </div>
      <div class="info-item">
        <div class="item-left">所属产品</div>
        <div style="color: #367CC5;cursor: pointer;" @click="handleProductDetail">{{ form && form.productName || "-" }}
        </div>
      </div>
      <div class="info-item first-left">
        <div class="item-left">产品PK</div>
        <div class="item-right">
          <span>{{ form && form.pk || "--" }}</span>
          <span class="copy-btn" style="display:inline-block" @click="copyToClipboard(form.pk)">复制</span>
        </div>
      </div>
      <div class="info-item  ">
        <div class="item-left">是否启用</div>
        <div>{{ form && form.enable ? "启用" : "禁用" }}</div>
      </div>
      <div class="info-item first-left">
        <div class="item-left">devSecret</div>
        <div class="item-right"><span>******</span> <span class="copy-btn"
            @click="copyToClipboard(form.devSecret)">复制</span></div>
      </div>
      <div class="info-item">
        <div class="item-left">当前状态</div>
        <div class="status-container" v-if="form && form.online">
          <div class="icon1"></div>
          <div>在线</div>
        </div>
        <div class="item-right-status" v-else>
          <div class="status-container">
            <div class="icon"></div>
            <div>离线</div>
          </div>

        </div>
      </div>

      <div class="info-item first-left ">
        <div class="item-left">节点类型</div>
        <div>{{ form.deviceType && getdeviceTypeStr(form.deviceType) || "--" }}</div>
      </div>
      <div class="info-item">
        <div class="item-left">IP地址</div>
        <div v-if="form.ipInfo">{{ form.ipInfo.ip }}({{ form.ipInfo.city }})</div>
        <div v-else>--</div>
      </div>
      <div class="info-item first-left">
        <div class="item-left">创建时间</div>
        <div>{{ form && form.createdDate || "--" }}</div>
      </div>
      <div class="info-item">
        <div class="item-left">激活时间</div>
        <div>{{ form && form.registerTime || "--" }}</div>
      </div>
      <div class="info-item first-left">
        <div class="item-left">最后上线时间</div>
        <div>{{ form && form.loginTime || "--" }}</div>
      </div>
      <div class="info-item">
        <div class="item-left">模组固件版本</div>
        <div>/</div>
      </div>
      <div class="info-item first-left ">
        <div class="item-left">MCU固件版本</div>
        <div>/</div>
      </div>
      <div class="info-item" v-if="parentGateway">
        <div class="item-left">所属网关</div>
        <div style="color: #367CC5;cursor: pointer;" @click="handleDetail">{{ parentGateway.name
          }}</div>
      </div>
    </div>
    <!-- 设备接入信息 - 标题 -->
    <div class="overview-item">
      <div class="overview-icon">
        <img src="@/assets/login/device-into.png" alt="">
      </div>
      <div class="overview-title">设备接入信息</div>
    </div>
    <!-- 设备接入信息 - 内容 -->
    <div class="info-container1">
      <div class="info-item" v-for="(item, index) of deviceLinksList" :key="index"
        :class="index % 2 === 0 ? 'first-left' : ''">
        <div class="item-left">{{ item.label }}</div>
        <div class="item-right">
          <div>
            <div v-for="(link, index) of item.links" :key="index" class="desc-content">
              <span>({{ link.schema }})</span>
              {{ link.host }}:{{ link.port }}
            </div>
          </div>
          <div class="look-btn" v-if="item.clientInfo">
            <el-button type="primary" text @click="handleLookMQTTInfo(item.clientInfo)">查看接入参数</el-button>
          </div>
        </div>
      </div>
    </div>
    <clientInfoDialog v-if="isCliendInfoShow" ref="clientInfoDialogRef" @close="() => isCliendInfoShow = false" />
  </div>
</template>

<script setup>
import clientInfoDialog from "./clientInfoDialog";
import { ElMessage } from "element-plus";
import { onMounted, ref, defineEmits, nextTick, getCurrentInstance } from "vue";
import api from '@/api/api';
import { useRoute, useRouter } from "vue-router";
import { copyToClipboard } from "@/hooks/common";
const emit = defineEmits(["changeName"]);
const isEdit = ref(false);
const route = useRoute();
const deviceLinksList = ref([]);
const router = useRouter()
const form = ref({});
const parentGateway = ref();
const isCliendInfoShow = ref(false);
const deviceTypeList = [
  {
    key: "GENERAL",
    label: "直连设备",
  },
  {
    key: "SWITCH",
    label: "中继",
  },
  {
    key: "GATEWAY",
    label: "网关",
  },
  {
    key: "TERMINAL",
    label: "终端子设备",
  }
]
const getdeviceTypeStr = (deviceType) => {
  let str = ""
  deviceTypeList.forEach(item => {
    if (item.key === deviceType)
      str = item.label
  })
  return str
}
let currentInstance = "";
onMounted(() => {
  currentInstance = getCurrentInstance();
  const { pk, devId } = route.query;
  api.getDeviceInfo(pk, devId).then(res => {
    if (res.code === "0") {
      const { deviceType, deviceLinks } = res.res.data
      deviceLinksList.value = deviceLinks;
      form.value = res.res.data;
      if (deviceType && (deviceType === 'TERMINAL' || deviceType === "SWITCH")) {
        // 根据parentDevId和parentPk 获取所属网关信息
        const { parentDevId, parentPk } = res.res.data;
        if (!parentPk || !parentDevId) return;
        api.getDeviceInfo(parentPk, parentDevId).then(result => {
          if (result.code === "0") {
            parentGateway.value = result.res.data;
          }
        })
      }
    }
  })
});
const edit = () => {
  if (!form.value || !form.value.name) return;
  const { pk, devId } = route.query;
  api.updateDeviceName({
    pk, devId,
    name: form.value.name
  }).then(res => {
    if (res.code === "0") {
      ElMessage.success("修改成功！");
      isEdit.value = false;
      emit("changeName", form.value.name)
    }
  })
}
const handleDetail = () => {
  const { pk, devId } = parentGateway.value
  sessionStorage.removeItem("handleDeviceTabsSelect");
  router.replace({
    path: "/product/device/details",
    query: { pk, devId }
  })
}

const handleProductDetail = () => {
  const { productName, pk } = form.value
  sessionStorage.removeItem("handleTabsSelect")
  router.push({
    path: "/product/product/details",
    query: { name: productName, pk }
  })
}
const handleLookMQTTInfo = (info) => {
  isCliendInfoShow.value = true;
  nextTick(() => {
    currentInstance.proxy.$refs.clientInfoDialogRef.init(info);
  });
}
</script>
<style lang="less" scoped>
.pk-conatiner {
  margin-top: 15px;
  display: flex;
  align-items: center;

  .overview-pk {
    font-size: 16px;
    line-height: 22px;
    color: #333;
  }

  .copy-btn {
    display: inline-block;
    width: 60px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    margin-left: 50px;
    color: #367CC5;
    cursor: pointer;
  }
}

.product-overview {
  box-sizing: border-box;
  padding: 0 50px;
  margin-top: 30px;

  .overview-item {
    // padding: 10px 0;
    display: flex;
    align-items: center;

    .overview-group {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .overview-left {
      display: flex;
      align-items: center;
    }

    .overview-icon {
      width: 18px;
      height: 20px;
      margin-right: 12px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .overview-title {
      font-weight: 700;
      font-size: 20px;
    }

    .copy-btn {
      // flex: 1;
      display: inline-block;
      width: 60px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      margin-left: 40px;
      color: #367CC5;
      cursor: pointer;
    }

    .copy-btn:hover,
    .copy-btn:active {
      background: rgba(1, 94, 224, .06);
    }
  }

  .info-container {
    margin-top: 15px;
    margin-bottom: 40px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .first-left {
      border-left: 1px solid #DCE0EB;
    }

    &>div:nth-child(1) {
      border-top: 1px solid #DCE0EB;
    }

    &>div:nth-child(2) {
      border-top: 1px solid #DCE0EB;
    }

    .info-item {
      width: 50%;
      border-bottom: 1px solid #DCE0EB;
      border-right: 1px solid #DCE0EB;
      box-sizing: border-box;
      display: flex;
      align-items: center;

      &>div {
        box-sizing: border-box;
        padding-right: 5px;
        word-break: break-all;
      }

      .item-left {
        padding: 20px 0 20px 15px;
        font-size: 16px;
        color: #656775;
        background: #F6F7F9;
        border-right: 1px solid #DCE0EB;
      }


      &>div:first-child {
        padding-left: 30px;
        font-size: 16px;
        color: #656775;
        width: 35%;
      }

      &>div:last-child {
        padding-left: 30px;
        font-size: 16px;
        color: #333333;
        width: 65%;
      }
    }


    .item-right {
      display: flex;
      justify-content: space-between;
      align-items: center;

      span {
        font-size: 16px;
      }

      .copy-btn {
        text-align: center;
        display: inline-block;
        width: 60px;
        height: 20px;
        line-height: 20px;
        margin-right: 10px;
        color: #367CC5;
        cursor: pointer;
      }

      .copy-btn:hover,
      .copy-btn:active {
        background: rgba(1, 94, 224, .06);
      }
    }
  }
}

.info-container1 {
  margin-top: 15px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;

  .first-left {
    border-left: 1px solid #DCE0EB;
  }

  &>div:nth-child(1) {
    border-top: 1px solid #DCE0EB;
  }

  &>div:nth-child(2) {
    border-top: 1px solid #DCE0EB;
  }

  .info-item {
    background: #F6F7F9;
    width: 100%;
    border-bottom: 1px solid #DCE0EB;
    border-right: 1px solid #DCE0EB;
    box-sizing: border-box;
    display: flex;
    align-items: center;

    &>div {
      box-sizing: border-box;
      padding-right: 5px;
      word-break: break-all;
    }

    &>div:first-child {
      padding-left: 30px;
      font-size: 16px;
      color: #656775;
      width: 17.5%;
    }

    &>div:last-child {
      border-left: 1px solid #DCE0EB;
      background: #ffffff;
      padding-left: 30px;
      font-size: 16px;
      color: #333333;
      width: 82.5%;
    }
  }

  .item-right1 {
    box-sizing: border-box;
    padding: 18px 0;

    span {
      color: #FF8611 !important;
    }
  }

  .item-right {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 18px 0;

    span {
      color: #FF8611 !important;
      font-size: 16px;
    }

    .copy-btn {
      text-align: center;
      display: inline-block;
      width: 40px;
      height: 20px;
      line-height: 20px;
      margin-right: 10px;
      color: #367CC5;
      cursor: pointer;
    }

    .copy-btn:hover,
    .copy-btn:active {
      background: rgba(1, 94, 224, .06);
    }
  }
}

.deviceLinks-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  .deviceLinks-group {
    display: flex;
    width: calc(100% / 3);

    &>div {
      width: 50%;
    }
  }
}

.desc-content {
  font-size: 16px;
  margin-top: 6px;
  line-height: 22px;
}

.desc-content:first-child {
  margin-top: 0;
}

.edit-btn {
  margin-top: 23px;
  cursor: pointer;
  width: 136px;
  height: 40px;
  text-align: center;
  line-height: 38px;
  box-sizing: border-box;
  border: 1px solid #367CC5;
  font-size: 16px;
  color: #367CC5;
  border-radius: 4px;
}

.look-btn {
  color: #367CC5;
  font-size: 16px;
}

.look-btn:deep(.el-button>span) {
  font-size: 16px;
  color: #367CC5;
}

.edit1-btn {
  color: #367CC5;
  font-size: 16px;
  margin-left: 40px;
  cursor: pointer;
}
</style>