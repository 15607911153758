<!--
 * @Description: 设备管理-设备详情
 * @Author: kecraft
 * @Date: 2024-01-10 15:08:02
 * @LastEditors: kecraft
 * @LastEditTime: 2024-05-29 10:04:47
 * @FilePath: /impact-iotos-console/src/views/device/device/detail/detail.vue
-->
<template>
  <div class="details">
    <div class="breadcrumb-conatiner">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/product/device/list' }">设备管理</el-breadcrumb-item>
        <el-breadcrumb-item>{{ deviceName }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="details-container">
      <div class="top-container">
        <div class="tabs-conatiner">
          <div v-for="i in tabs" :key="i" class="tab-item" :class="i !== selectIndex ? 'is_select' : ''"
            @click="handleTabChanged(i)" v-domPreventReClick>
            <div>{{ i }}</div>
            <div class="line" v-if="i === selectIndex"></div>
            <div class="line-none" v-else></div>
          </div>
        </div>
      </div>
      <div class="main-container">
        <el-scrollbar style="height: 100%">
          <overview v-if="selectIndex === '设备信息'" @changeName="changeName" />
          <topicList v-if="selectIndex === 'Topic列表'" />
          <deviceShadow v-if="selectIndex === '设备影子'" />
          <logsList v-if="selectIndex === '上下行数据'" />
          <indicatorTrend v-if="selectIndex === '指标趋势'" />
          <indicatorPoly v-if="selectIndex === '指标聚合'" />
          <topoList v-if="selectIndex === '子设备管理'" />
          <feature v-if="selectIndex === '功能定义'" />
          <deviceControl v-if="selectIndex === '设备控制'" />
        </el-scrollbar>
      </div>
    </div>
  </div>
</template>

<script setup>
import overview from './overview';
import topicList from "./topic/topicList";
import deviceShadow from './shadow/deviceShadow';
import logsList from './logs/logsList';
import indicatorTrend from './indicatorTrend/indicatorTrend';
import indicatorPoly from './indicatorTrend/indicatorPoly';
import topoList from './topo/topoList';
import feature from './feature/feature';
import deviceControl from './control/deviceControl';
import { ref, onMounted } from 'vue';
import { useRoute } from "vue-router";
import api from '@/api/api';
const route = useRoute();
const deviceName = ref("");
const selectIndex = ref("");
const tabs = ref(["设备信息", "功能定义", "设备影子", "上下行数据", "设备控制", "指标趋势", "指标聚合"]);
onMounted(() => {
  const lastSelect = sessionStorage.getItem("handleDeviceTabsSelect") || "设备信息";
  const { pk, devId } = route.query
  if (lastSelect) selectIndex.value = lastSelect;
  if (!pk && !devId) return;
  api.getDeviceInfo(pk, devId).then(res => {
    if (res.code === "0") {
      const { name, deviceType } = res.res.data;
      deviceName.value = name
      if (deviceType === "GATEWAY") {
        tabs.value = ["设备信息", "功能定义", "Topic列表", "设备影子", "上下行数据", "设备控制", "子设备管理", "指标趋势", "指标聚合"];
      } else if (deviceType === "GENERAL") {
        tabs.value = ["设备信息", "功能定义", "Topic列表", "设备影子", "上下行数据", "设备控制", "指标趋势", "指标聚合"];
      }
    }
  })
})
const handleTabChanged = (v) => {
  if (v === selectIndex.value) return;
  selectIndex.value = v;
  sessionStorage.setItem("handleDeviceTabsSelect", v)
}
const changeName = (val) => {
  deviceName.value = val;
}
</script>

<style lang="less" scoped>
.breadcrumb-conatiner {
  box-sizing: border-box;
  padding: 20px 28px;
  border-bottom: 1px solid #E9ECF0;
  width: 100%;
}

.details {
  width: 100%;
  box-sizing: border-box;
}

.tabs-conatiner {
  display: flex;
  box-sizing: border-box;
  padding-top: 23px;
  padding-left: 30px;
  margin-left: 19px;
  margin-right: 19px;
  border-bottom: 1px solid #EEEEEE;

  .top-container {
    border-bottom: 1px solid #EEEEEE;
  }

  .tab-item {
    text-align: center;
    line-height: 25px;
    box-sizing: border-box;
    margin-right: 30px;
    font-size: 18px;
    cursor: pointer;
    // border: 3px solid transparent;
    // margin-bottom: 12px;
  }

  .line {
    margin-top: 12px;
    width: 100%;
    height: 3px;
    background: #367CC5;
  }

}

.main-container {
  height: 80vh;
  background: #fff;
}
</style>