/*
 * @Description: 
 * @Author: kecraft
 * @Date: 2024-01-16 09:22:18
 * @LastEditors: kecraft
 * @LastEditTime: 2024-03-13 15:29:16
 * @FilePath: /impact-iotos-console/src/hooks/deviceEnum.js
 */
/**
 * 节点类型
 * GENERAL、SWITCH、GATEWAY、TERMINAL
 */
const deviceTypeList = [
  {
    key: "GENERAL",
    label: "直连设备",
  },
  {
    key: "SWITCH",
    label: "中继",
  },
  {
    key: "GATEWAY",
    label: "网关",
  },
  {
    key: "TERMINAL",
    label: "终端子设备",
  }
]

/**
 * 设备状态
 */
const onlineList = [
  {
    key: true,
    label: "在线",
  },
  {
    key: false,
    label: "离线",
  },
]

export {
  deviceTypeList,
  onlineList
}