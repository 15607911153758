<!--
 * @Description: 设备管理-上下行数据
 * @Author: kecraft
 * @Date: 2024-01-11 08:54:33
 * @LastEditors: kecraft
 * @LastEditTime: 2024-05-31 09:25:34
 * @FilePath: /impact-iotos-console/src/views/device/device/detail/logs/logsList.vue
-->
<template>
  <div class="logs-list">
    <div class="search-conatiner">
      <div class="form-item">
        <span>选择事件：</span>
        <el-select v-model="action" placeholder="请选择事件" class="width180" @change="handleActionChange">
          <el-option v-for="item in actionList" :key="item.action" :label="item.action" :value="item.action" />
        </el-select>
      </div>
      <div class="form-item" v-if="action === 'devSend' || action === 'cloudSend'">
        <span>选择命令：</span>
        <el-select v-model="query.cmd" placeholder="请选择命令" class="width180" @change="search">
          <el-option v-for="item in cmdList" :key="item.cmd" :label="item.name" :value="item.cmd" />
        </el-select>
      </div>
      <div class="form-item">
        <span>选择时间段：</span>
        <el-select v-model="query.dateType" placeholder="请选择" class="width180" @change="handleDateChange" clearable>
          <el-option v-for="item in dateTypeList" :key="item.key" :label="item.label" :value="item.key" />
        </el-select>
      </div>
      <div class="form-item">
        <span>选择时间：</span>
        <el-date-picker v-model="times" type="datetimerange" range-separator="至" start-placeholder="开始时间"
          end-placeholder="结束时间" @change="handleTimeChange" :clearable="false" />
      </div>

    </div>
    <div class="search-conatiner mt30">
      <div class="search-btn" @click="search" v-preventReClick>搜索</div>
      <div class="form-item" style="flex:1">
        <div class="reset-btn" @click="reset" v-preventReClick>重置</div>
      </div>
    </div>

    <div class="tables">
      <el-table stripe :data="logsList" empty-text="暂无数据" v-loading="isload" style="width: 100%;">
        <el-table-column width="180" label="事件" prop="action" align="center" />
        <el-table-column width="140" label="传输类型" prop="frameType" align="center">
          <template #default="scope">
            {{ getframeTypeStr(scope.row.frameType) }}
          </template>
        </el-table-column>
        <el-table-column min-width="220" label="请求数据" prop="request" align="left" />
        <el-table-column width="140" label="结果" prop="desc" align="center">
          <template #default="scope">
            <el-button type="success" v-if="scope.row.isSuccess" plain>成功</el-button>
            <el-button type="danger" v-else plain>失败</el-button>
          </template>
        </el-table-column>
        <el-table-column width="260" label="创建时间" prop="timestamp" align="left">
          <template #default="scope">
            {{ scope.row.timestamp ? moment(scope.row.timestamp).format('YYYY-MM-DD HH:mm:ss') : "--" }}
          </template>
        </el-table-column>
        <el-table-column width="140" label="操作" align="center">
          <template #default="scope">
            <div class="column-btn">
              <div class="options">
                <span link style="color:#015ee0;cursor: pointer;padding: 0 6px;" @click="handleDetail(scope.row)">
                  数据追踪
                </span>
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="footer-conatiner">
      <pagination :total="total" @pageChange="getList" class="pagination" layout="sizes, prev, pager, next" />
    </div>
    <dataTracking v-if="isTrack" ref="dataTrackingRef" @close="() => isTrack = false" />
  </div>
</template>

<script setup>
import moment from "moment";
import pagination from "@/components/control/pagination";
import dataTracking from "./dataTracking";
import { onMounted, reactive, ref, getCurrentInstance, nextTick } from 'vue';
import api from '@/api/api';
import { useRoute } from "vue-router";
import { frameTypeList } from "@/hooks/productEnum";
const route = useRoute();
const isTrack = ref(false);
const actionList = ref([]);
const action = ref("");
const times = ref([]); // 时间
const logsList = ref([]);
const cmdList = ref([]);
const isload = ref(false);
const total = ref(0);
const query = reactive({
  dateType: "DAY",
  pk: "",
  devId: "",
  action: "", // 事件
  startTime: '',
  endTime: "",
  cmd: '',
  page: 0,
  size: 10,
})
const dateTypeList = ref([
  {
    key: "HOUR",
    label: "近一小时"
  },
  {
    key: "DAY",
    label: "近一天"
  },
  {
    key: "WEEK",
    label: "近一周"
  }
])

const handleDateChange = () => {
  if (query.dateType === "DAY") {
    const endTime = (new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1)
    const end = new Date(endTime)
    const startTime = new Date(new Date().toLocaleDateString()).getTime() - 24 * 60 * 60 * 1000
    const start = new Date(startTime)
    times.value = [start, end]
  } else if (query.dateType === "HOUR") {
    const endTime = new Date().getTime()
    const end = new Date(endTime)
    const startTime = new Date().getTime() - 60 * 60 * 1000
    const start = new Date(startTime)
    times.value = [start, end]
  } else if (query.dateType === "WEEK") {
    const endTime = (new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1)
    const end = new Date(endTime)
    const startTime = new Date(new Date().toLocaleDateString()).getTime() - 7 * 24 * 60 * 60 * 1000
    const start = new Date(startTime)
    times.value = [start, end]
  }
}
const getframeTypeStr = (frameType) => {
  let str = ''
  frameTypeList.forEach(item => {
    if (item.value == frameType) {
      str = item.label
    }
  })
  return str;
}
let currentInstance = "";
onMounted(() => {
  currentInstance = getCurrentInstance();
  const { pk, devId } = route.query
  if (!pk || !devId) return;
  query.pk = pk;
  query.devId = devId;
  getActions();
  const endTime = (new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1)
  const end = new Date(endTime)
  const startTime = new Date(new Date().toLocaleDateString()).getTime() - 24 * 60 * 60 * 1000
  const start = new Date(startTime)
  times.value = [start, end]
  search();
})

const reset = () => {
  const endTime = (new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1)
  const end = new Date(endTime)
  const startTime = new Date(new Date().toLocaleDateString()).getTime() - 24 * 60 * 60 * 1000
  const start = new Date(startTime)
  times.value = [start, end]
  action.value = ""
  query.action = query.cmd = "";
  query.dateType = "DAY";
  search();
}
const handleActionChange = () => {
  const { pk } = route.query
  query.cmd = ""
  api.protocolModel(pk).then(res => {
    if (res.code === "0") {
      const { cmds } = res.res.data;
      if (action.value === "devSend") {
        // 上报
        const sendCmds = []
        cmds.forEach(item => {
          if (item.frameType === "DEV_UP") {
            sendCmds.push(item);
          }
        })
        cmdList.value = sendCmds;
      } else if (action.value === "cloudSend") {
        // 下发
        const downCmds = []
        cmds.forEach(item => {
          if (item.frameType === "DEV_DOWN") {
            downCmds.push(item);
          }
        })
        cmdList.value = downCmds;
      } else {
        cmdList.value = [];
      }
    }
  })
}
const getActions = () => {
  api.getAllActions().then(res => {
    if (res.code === "0") {
      const actions = res.res.data
      const all = {
        action: "全部",
      }
      actionList.value = [all, ...actions];
      action.value = "全部"
    }
  })
}
const search = () => {
  const { pk, devId } = route.query
  if (!pk || !devId) return;
  if (times.value && times.value.length === 2) {
    query.startTime = times.value[0].getTime();
    query.endTime = times.value[1].getTime();
  }
  if (action.value !== "全部") {
    query.action = action.value
  } else {
    query.action = ""
  }
  isload.value = true;
  api.getDevicePackage(pk, devId, query).then(res => {
    if (res.code === "0") {
      const list = res.res.data;
      list.forEach(item => {
        if (!item.response) return;
        const json = JSON.parse(item.response)
        if (json && json.code === 0) {
          item.isSuccess = true
        } else {
          item.isSuccess = false;
        }
      })
      logsList.value = list;
      if (res.res.data.length === query.size) {
        total.value = (query.page + 1) * (query.size) + 1;
      } else {
        total.value = (query.page) * (query.size) + res.res.data.length;
      }
      isload.value = false;
    }
  })
}
/**
 * 数据追踪
 */
const handleDetail = (row) => {
  isTrack.value = true;
  nextTick(() => {
    currentInstance.proxy.$refs.dataTrackingRef.init(row);
  });
}
const getList = (v) => {
  query.page = v.pageNum - 1
  if (v.pageSize !== query.size) {
    total.value = 0;
    query.page = 0;
  }
  query.size = v.pageSize
  search();
}

const handleTimeChange = () => {
  search();
}
</script>

<style lang="less" scoped>
.mt30 {
  margin-top: 20px;
}

.width180 {
  width: 160px;
}

.reset-btn {
  cursor: pointer;
  width: 92px;
  height: 36px;
  justify-content: center;
  display: flex;
  align-items: center;
  border-radius: 4px;
  box-sizing: border-box;
  font-weight: lighter;
  border: 1px solid #AFB9CB;
  color: #333333;
  font-size: 16px;
}

.search-btn {
  margin-right: 10px;
  cursor: pointer;
  width: 92px;
  height: 36px;
  text-align: center;
  line-height: 36px;
  box-sizing: border-box;
  background: #367CC5;
  border-radius: 4px;
  font-size: 14px;
  color: #FFFFFF;
}

.search-btn:hover,
.search-btn:active {
  background: #015ee0;
  color: #fff;
}

.search-conatiner {
  display: flex;
  align-items: center;

  .form-item {
    margin-right: 30px;
    display: flex;
    align-items: center;

    span {
      color: #606266;
    }
  }
}

.logs-list {
  box-sizing: border-box;
  padding: 30px 50px;
}

.search-container {
  display: flex;
  align-items: center;

  .search-item {
    display: flex;
    align-items: center;


    &>div:first-child {
      font-size: 14px;
      color: rgb(123, 132, 140);
    }
  }

  .search-item:nth-child(2) {
    margin-left: 40px;
  }

  .search-item:last-child {
    flex: 1;
    justify-content: flex-end;
  }
}

.btn-normal :deep(.el-button) {
  font-size: 14px;
  height: 36px;
  background: #015ee0;
}

.btn-normal :deep(.el-button:hover) {
  font-size: 14px;
  height: 36px;
  background: #337ecc;
}


.btn-normal :deep(.el-button:active) {
  font-size: 14px;
  height: 36px;
  background: #337ecc;
}

.tables {
  margin-top: 40px;
}

:deep(.el-table th.el-table__cell) {
  background-color: #F6F7F9;
  height: 50px;
  font-weight: normal;
}

:deep(.el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell) {
  background-color: #FBFBFB;
}

:deep(.el-table td.el-table__cell div) {
  font-size: 16px;
  line-height: 50px;
}

.footer-conatiner {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}
</style>