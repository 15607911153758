<!--
 * @Description: 子设备管理 - 新增
 * @Author: kecraft
 * @Date: 2024-01-15 10:41:09
 * @LastEditors: kecraft
 * @LastEditTime: 2024-05-28 09:45:29
 * @FilePath: /impact-iotos-console/src/views/device/device/detail/topo/topoAdd.vue
-->
<template>
  <div class="topo-add">
    <myDialog @close="handleClose" @ok="handleOk" title="添加子设备">
      <div class="width700">
        <el-form :rules="rules" ref="formtopoAdd" :model="form" label-position="left">
          <el-form-item label="所属产品：" prop="pk" class="label-feather ">
            <el-select v-model="form.pk" placeholder="请选择所属产品" class="width500" @change="handlePkChange" filterable>
              <el-option v-for="item in productList" :key="item.pk" :label="item.name" :value="item.pk" />
            </el-select>
          </el-form-item>
          <el-form-item label="设备：" prop="devId" class="label-feather ">
            <el-select v-model="form.devId" placeholder="请选择设备" class="width500" filterable>
              <el-option v-for="item in deviceList" :key="item.devId" :label="item.name" :value="item.devId" />
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div class="tooltip">只能选择节点类型为中继设备或终端子设备的产品。</div>
    </myDialog>
  </div>
</template>

<script setup>
import myDialog from "@/components/dialog/myDialog.vue";
import { reactive, ref, onMounted, defineEmits } from "vue";
import { ElMessage } from "element-plus";
import api from '@/api/api';
import { useRoute } from "vue-router";
const route = useRoute()
const emit = defineEmits(["close"]);
const form = reactive({
  pk: "",
  devId: "",
})
const rules = {
  pk: [
    { required: true, message: "所属产品不能为空", trigger: "change" },
  ],
  devId: [
    { required: true, message: "所属产品不能为空", trigger: "change" },
  ],
}
const productList = ref([]);
const deviceList = ref([]);
const formtopoAdd = ref(null);
onMounted(() => {
  api.getAllProducts().then(res => {
    if (res.code === "0") {
      const list = res.res.data;
      const filterList = [];
      list.forEach(item => {
        if (item.deviceType === 'SWITCH' || item.deviceType === "TERMINAL") {
          filterList.push(item);
        }
      })
      productList.value = filterList;
    }
  })
})
const handlePkChange = () => {
  if (!form.pk) return;
  deviceList.value = "";
  form.devId = "";
  api.getDeviceByPk(form.pk).then(res => {
    if (res.code === "0") {
      deviceList.value = res.res.data;
    }
  })

}
const handleClose = () => {
  emit("close", false)
}
const handleOk = () => {
  formtopoAdd.value.validate((valid) => {
    if (valid) {
      const { pk, devId } = route.query;
      api.deviceAddTopo(pk, devId, form).then(res => {
        if (res.code === "0") {
          ElMessage.success("创建成功！")
          emit("close", true)
        }
      })
    } else {
      return false;
    }
  });
}
</script>

<style lang="less" scoped>
.width500 {
  width: 500px;
}

.tooltip {
  margin-top: 20px;
  font-size: 14px;
  color: #5E6B86;
  background: #DDE6F3;
  box-sizing: border-box;
  padding: 12px 25px;
  border-radius: 4px;
}
</style>

<style lang="less">
.label-feather {
  .el-form-item__label {
    width: 100px;
  }
}
</style>