var websock = null
var cb = null
var agentData = null
let isloading = false;
function initWebSocket(url) {
    if (isloading) return;
    isloading = true;
    
    websock = new WebSocket(url)
    /**
     * 连接
     */
    websock.onopen = function () {
        websocketOpen()
    }

    /**
     * 接收数据
     * @param {*} e 
     */
    websock.onmessage = function (e) {
        if (e.data !== "PONG") {
            websocketonmessage(e)
        }
        heartCheck.reset();
    }

    /**
     * 关闭连接
     */
    websock.onclose = function (err) {
        console.log(err);
        // todo 
        // if (localStorage.getItem('impact-iotos-token')) {
        //     reconnect(url)
        // } else {
        //     websocketclose()
        // }
    }

    /**
     * 错误重连
     */
    websock.onerror = function (e) {
        console.log("onerror", e);
        const token_url = process.env.VUE_APP_TOKEN
        if (localStorage.getItem(token_url)) {
            reconnect(url)
        } else {
            websocketclose()
        }
    }
}

// 实际调用的方法
function sendSock(agentData1, callback) {
    cb = callback
    agentData = agentData1
    if (websock.readyState === websock.OPEN) {
        // 若是ws开启状态
        websock.send(agentData)
    } else if (websock.readyState === websock.CONNECTING) {
        // 若是 正在开启状态，则等待1s后重新调用
        setTimeout(function () {
            sendSock(agentData, callback)
        }, 1000)
    } else {
        // 若未开启 ，则等待1s后重新调用
        setTimeout(function () {
            sendSock(agentData, callback)
        }, 1000)
    }
}

// 数据接收
function websocketonmessage(e) {
    cb(e)
}

// 关闭
function websocketclose() {
    websock.close()
}

function websocketOpen() {
    console.log('连接成功')
    isloading = false;
    heartCheck.start();
}

function reconnect(url) {
    // console.log('心跳重连token')
    if (reconnect.lockReconnect) return
    reconnect.lockReconnect = true
    setTimeout(function () {
        initWebSocket(url)
        reconnect.lockReconnect = false
    }, 2000)
}
var heartCheck = {
    timeout: 15000,//30秒
    timeoutObj: null,
    reset: function () {//接收成功一次推送，就将心跳检测的倒计时重置为30秒
        clearTimeout(this.timeoutObj);//重置倒计时
        this.start();
    },
    start: function () {//启动心跳检测机制，设置倒计时30秒一次
        this.timeoutObj = setTimeout(function () {
            if (websock) {
                websock.send("PING");//启动心跳
            }
        }, this.timeout)
    }
    //onopen连接上，就开始start及时，如果在定时时间范围内，onmessage获取到了服务端消息，就重置reset倒计时，距离上次从后端获取消息30秒后，执行心跳检测，看是不是断了。
}

export default { sendSock, initWebSocket, websocketclose }
