<!--
 * @Description: 设备控制
 * @Author: kecraft
 * @Date: 2024-02-02 09:09:51
 * @LastEditors: kecraft
 * @LastEditTime: 2024-06-07 13:23:43
 * @FilePath: /impact-iotos-console/src/views/device/device/detail/control/deviceControl.vue
-->
<template>
  <div class="device-control">
    <!-- 模拟下发 -->
    <div class="control-container">
      <div class="item-title">
        <img src="@/assets/login/product.png" alt="">
        模拟下发
      </div>
      <div class="search-container">
        <el-form ref="formControlAddRef" label-position="left">
          <el-form-item class="label-feather" label="选择命令">
            <el-select v-model="query.cmd" placeholder="请选择命令" @change="getForm" class="item-label">
              <el-option v-for="item in cmdList" :key="item.cmd" :label="item.name" :value="item.cmd" />
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div class="cmd-container">
        <!-- <el-scrollbar style="height: 100%" wrap-style="overflow-x:hidden;"> -->
        <el-form ref="formControlAddRef" :model="form" label-position="left">
          <div v-for="(item, index) of formList" :key="index">
            <!-- 长度校验 -->
            <el-form-item class="label-feather" v-if="item.checkType.type === 'LENGTH'" :label="item.name"
              :prop="item.param" :rules="{
              required: true,
              min: item.checkType.min.value,
              max: item.checkType.max.value,
              message: `长度在 ${item.checkType.min.value} 到 ${item.checkType.max.value} 个字符`,
              trigger: 'blur'
            }
              ">
              <el-input v-model="form[item.param]" placeholder="请输入" />
            </el-form-item>
            <!-- 范围校验 -->
            <el-form-item class="label-feather" v-if="item.checkType.type === 'RANGE'" :label="item.name"
              :prop="item.param" :rules="{
              required: true,
              message: `${item.name}不能为空`,
              trigger: 'blur'
            }
              ">
              <el-input-number v-model="form[item.param]" :max="item.checkType.max.value"
                :min="item.checkType.min.value" style="width:100%" v-if="item.dataType === 'INT'" />
              <el-input-number v-model="form[item.param]" :max="item.checkType.max.value"
                :min="item.checkType.min.value" style="width:100%" v-if="item.dataType === 'FLOAT'" :precision="2" />
            </el-form-item>
            <!-- 不校验 -->
            <el-form-item class="label-feather" v-if="item.checkType.type === 'NONE'" :label="item.name"
              :prop="item.param" :rules="{
              required: true,
              message: `${item.name}不能为空`,
              trigger: 'blur'
            }
              ">
              <el-input v-model="form[item.param]" placeholder="请输入" v-if="item.dataType === 'STRING'" />
              <el-input-number v-model="form[item.param]" placeholder="请输入" v-if="item.dataType === 'INT'" />
              <el-input-number v-model="form[item.param]" placeholder="请输入" :precision="2"
                v-if="item.dataType === 'FLOAT'" />
            </el-form-item>
            <!-- 枚举校验 -->
            <el-form-item class="label-feather" v-if="item.checkType.type === 'ENUM'" :label="item.name"
              :prop="item.param" :rules="{
              required: true,
              message: `${item.name}不能为空`,
              trigger: 'blur'
            }
              ">
              <el-select v-model="form[item.param]" placeholder="请选择" style="width: 100%;">
                <el-option v-for="(a, eIndex) of     item.checkType.values    " :key="eIndex" :label="a.desc"
                  :value="a.value" />
              </el-select>
            </el-form-item>
          </div>
        </el-form>
        <!-- </el-scrollbar> -->
      </div>
      <div class="btn-groups" @click="handleSend">
        立即发送
      </div>
    </div>
    <div class="right-container">
      <el-scrollbar style="height: 78vh" wrap-style="overflow-x:hidden;">
        <div class="message-container">
          <div class="item-title-right">
            <div>
              <img src="@/assets/icon/edit-icon.png" alt="">
              消息追踪
            </div>
            <div class="right-box">
              <div v-if="isOpen" @click="() => isOpen = false">
                收起
                <img src="@/assets/newicon/up.png">
              </div>
              <div v-else @click="() => isOpen = true">
                展开
                <img src="@/assets/newicon/down.png">
              </div>
            </div>
          </div>
          <div class="track-box" v-if="showTrackList && showTrackList.length > 0 && isOpen">
            <div class="track-item-box" v-for="(item, index) of showTrackList" :key="index">
              <div class="track-title">
                <div>
                  traceId:{{ item.traceId }}
                  <span class="copy-btn" @click="copyToClipboard(item.traceId)">复制</span>
                </div>
                <div class="btn-right">
                  <img src="@/assets/newicon/up.png" class="isOpen" v-if="item.isOpen"
                    @click="() => item.isOpen = false">
                  <img src="@/assets/newicon/down.png" v-else @click="() => item.isOpen = true">
                </div>
              </div>
              <div v-if="item.isOpen" class="mt20">
                <div v-for="(tarck, tIndex) of item.showList" :key="tIndex" class="track-item">
                  <div class="item-title2">
                    <div class="item-point"></div>
                    <div class="item-name">
                      {{ tarck.label }}
                    </div>
                  </div>
                  <div class="item-label1">
                    <div class="item-line" :class="tIndex !== item.showList.length - 1 ? 'show-line ' : ''">
                      {{ item[tarck.key] }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="shadow-container">
          <div class="item-title-right">
            <div>
              <img src="@/assets/login/shadow.png" alt="">
              设备影子
            </div>
          </div>
          <div class="title-container">
            <div>参数名称</div>
            <div>状态值</div>
          </div>
          <div class="info-container1">
            <div class="info-item first-lef" v-for="(item, index) of shadowList" :key="index">
              <div class="item-left">{{ item.name }}</div>
              <div class="item-right">
                {{ item.value || "--" }}
              </div>
            </div>
          </div>
        </div>
      </el-scrollbar>
    </div>
  </div>
</template>

<script setup>
import moment from "moment"
import { reactive, ref, onMounted, onBeforeUnmount } from 'vue';
import { copyToClipboard } from "@/hooks/common";
import { useRoute } from 'vue-router';
import { ElMessage } from "element-plus";
import api from '@/api/api';
import ws from "@/utils/webSocket";
import { javaWs } from "@/utils/config";
const shadowList = ref([]);
const isload = ref(false);
const route = useRoute();
const form = reactive({})
const formList = ref([]);
const paramsList = ref([]);
const allParamsList = ref([]);
const isOnline = ref(false);
const showTrackList = ref([]);
const isOpen = ref(false)
const query = reactive({
  cmd: "",
})
const submitParam = ref(null);
const cmdList = ref([]);
onMounted(() => {
  const { pk, devId } = route.query;
  if (!pk && devId) return;
  getProtocolModel()
  initWebSocket();
})
const upList = [
  {
    label: "设备上报",
    key: "rawRequest",
  },
  {
    label: "DLink解析",
    key: "request",
  },
  {
    label: "服务端响应",
    key: "response",
  },
  {
    label: "服务端下发至设备",
    key: "response",
  },
]

const downListSuccess = [
  {
    label: "应用下发请求数据",
    key: "rawRequest",
  },
  {
    label: "服务端响应",
    key: "response",
  },
  {
    label: "服务端下发至设备",
    key: "rawRequest",
  }
]

const downListError = [
  {
    label: "应用下发请求数据",
    key: "rawRequest",
  },
  {
    label: "服务端响应",
    key: "response",
  },
]

const getDeviceInfo = () => {
  const { pk, devId } = route.query;
  api.getDeviceInfo(pk, devId).then(res => {
    if (res.code === "0") {
      const { online } = res.res.data;
      isOnline.value = online;
    }
  })
}
const initWebSocket = () => {
  const { pk, devId } = route.query;
  const token_url = process.env.VUE_APP_TOKEN
  let tokenstr = localStorage.getItem(token_url) || ""
  if (tokenstr) {
    tokenstr = tokenstr.split(" ")[1]
  }
  const url = javaWs + `?CLIENT_IP=127.0.0.1&CLIENT_ID=device:${pk},${devId}&SUBSCRIBE=device_snapshot,device_online&TOKEN=${tokenstr}`;
  ws.initWebSocket(url);
  ws.sendSock("PING", wsMessage);
}
const wsMessage = (e) => {
  if (e && e.data) {
    var obj = JSON.parse(e.data);
    if (obj && obj.type === "device_snapshot") {
      dealResult(obj.data);
    } else if (obj && obj.type === "device_online") {
      isOnline.value = obj.data;
    } else if (obj && obj.type === "device_packet") {
      dealTrack(obj.data)
    }
  }
}
const dealTrack = (data) => {
  const { frameType, response } = data
  let isSuccess = false;
  if (response.code === 0) {
    isSuccess = true;
  }
  if (frameType === "DEV_UP") {
    data.showList = upList;
  } else {
    if (isSuccess) {
      data.showList = downListSuccess
    } else {
      data.showList = downListError
    }
  }
  showTrackList.value.push(data)
}
const getProtocolModel = () => {
  getDeviceInfo();
  const { pk } = route.query
  isload.value = true;
  api.protocolModel(pk).then(res => {
    if (res.code === "0") {
      const { params } = res.res.data;
      allParamsList.value = params;
      search();
    }
  })
}
const search = () => {
  const { pk, devId } = route.query;
  if (!pk || !devId) return;
  api.getSnapshot({ pk, devId }).then(res => {
    if (res.code === "0" && res.res.data.data) {
      dealResult(res.res.data, 1);
      isload.value = false;
    } else {
      isload.value = false;
    }
  })
}

const dealResult = (data, type) => {
  const { params } = data.data;
  const time = data.timestamp
  let lastTime = "--"
  if (time) {
    lastTime = moment(time).format('YYYY-MM-DD HH:mm:ss')
  }
  const list = allParamsList.value
  for (let i in params) {
    list.forEach(item => {
      if (item.checkType.type === "ENUM") {
        // 如果是枚举 则需要去取 desc
        const enums = item.checkType.values;
        enums.forEach(a => {
          if (a.value === params[i]) {
            if (type === 1) {
              if (i == item.param) {
                item.value = a.value + ":" + a.desc;
              }
              item.lastTime = lastTime;
            } else {
              if (i == item.param) {
                item.value = a.value + ":" + a.desc;
                item.lastTime = lastTime;
              }
            }
          }
        })
      } else {
        if (type === 1) {
          if (i == item.param) {
            item.value = params[i]
          }
          item.lastTime = lastTime;
        } else {
          if (i == item.param) {
            item.value = params[i]
            item.lastTime = lastTime;
          }
        }
      }

    })
  }
  shadowList.value = list;
}
onBeforeUnmount(() => {
  if (ws) {
    ws.websocketclose();
    console.log("关闭连接");
  }
})
const getCmdList = () => {
  const { pk } = route.query;
  api.protocolModel(pk).then(res => {
    if (res.code === "0") {
      const { cmds, params } = res.res.data;
      paramsList.value = params;
      const downLoadList = [];
      cmds.forEach(item => {
        if (item.frameType === "DEV_DOWN") {
          downLoadList.push(item);
        }
      })
      cmdList.value = downLoadList;
      if (downLoadList.length > 0) {
        query.cmd = downLoadList[0].cmd;
        getForm()
      }
    }
  })
}
getCmdList();
const formControlAddRef = ref(null);
const getForm = () => {
  const cmdValue = query.cmd;
  const params = paramsList.value
  submitParam.value = null;
  formList.value = [];
  const showList = [];
  let cmdParams = []
  cmdList.value.forEach(item => {
    if (item.cmd === cmdValue) {
      cmdParams = item.params;
      submitParam.value = item;
    }
  })
  if (cmdParams.length > 0) {
    cmdParams.forEach(cmd => {
      params.forEach(param => {
        if (cmd === param.param) {
          if (param.dataType === "STRING") {
            form[param.param] = ""
          } else if (param.dataType === "INT") {
            if (param.checkType.type !== "ENUM") {
              form[param.param] = 0
            } else {
              form[param.param] = ""
            }

          } else if (param.dataType === "FLOAT") {
            if (param.checkType.type !== "ENUM") {
              form[param.param] = 0.00
            } else {
              form[param.param] = ""
            }
          }
          showList.push(param)
        }
      })
    })
  }
  formList.value = showList;
}

const handleSend = () => {
  formControlAddRef.value.validate((valid) => {
    if (valid) {
      const { pk, devId } = route.query;
      const { cmd, params } = submitParam.value;
      const obj = {}
      params.forEach(item => {
        obj[item] = form[item]
      })
      const info = {
        cmd,
        params: obj
      }
      api.deviceClondSend(pk, devId, info).then(res => {
        if (res.code === "0") {
          const { code, desc } = res.res.data;
          if (code === 0) {
            ElMessage.success("下发成功!")
          } else {
            ElMessage.error(desc);
          }

        }
      })
    } else {
      return false;
    }
  });
}
</script>

<style lang="less" scoped>
.mt20 {
  margin-top: 20px;
}

.right-box {
  color: #AFB9CB;
  font-size: 16px;
  font-weight: lighter;
  cursor: pointer;

  &>div {
    display: flex;
    align-items: center;

    img {
      width: 11px !important;
      height: 6px !important;
      margin-left: 15px !important;
    }
  }

}

.btn-right {
  display: flex;
  align-items: center;

  img {
    width: 11px !important;
    height: 6px !important;
    margin-right: 15px;
  }

}

.track-item-box {
  margin-top: 20px;
  box-sizing: border-box;
  padding: 16px 14px 15px 14px;
  background: #F9FCFD;
  border: 1px dashed #E9ECF0;
  margin-bottom: 10px;
}

.track-item {

  .item-title2 {
    display: flex;
    align-items: center;
    line-height: 10px;

    .item-point {
      width: 12px;
      height: 12px;
      margin-right: 10px;
      background: #367CC5;
      border-radius: 50%;
    }

    .item-name {
      margin-left: 15px;
    }
  }

  .item-label1 {
    display: flex;
    align-items: center;

    .item-line {
      box-sizing: border-box;
      color: #656775;
      font-weight: lighter;
      padding: 10px 30px;
      padding-right: 0;
      padding-bottom: 30px;
      margin-left: 5px;
      word-break: break-all;
    }

    .show-line {
      border-left: 2px solid #367CC5;
    }
  }
}

.track-box {
  box-sizing: border-box;
  padding: 15px 30px;
  padding-top: 0;

  .track-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #333333;
  }

  .copy-btn {
    display: inline-block;
    width: 60px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    margin-left: 50px;
    color: #367CC5;
    cursor: pointer;
  }
}

.title-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  padding: 0 30px;
  margin-top: 15px;

  &>div:first-child {
    padding-left: 30px;
    font-size: 16px;
    color: #367CC5;
    width: 33%;
  }

  &>div:last-child {
    padding-left: 20px;
    font-size: 16px;
    color: #367CC5;
    width: 67%;
  }
}

.info-container1 {
  box-sizing: border-box;
  padding: 0 30px;
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  padding-bottom: 40px;

  .first-left {
    border-left: 1px solid #DCE0EB;
  }

  &>div:nth-child(1) {
    border-top: 1px solid #DCE0EB;
  }

  &>div:nth-child(2) {
    border-top: 1px solid #DCE0EB;
  }

  .info-item {
    background: #F6F7F9;
    width: 100%;
    border-left: 1px solid #DCE0EB;
    border-bottom: 1px solid #DCE0EB;
    border-right: 1px solid #DCE0EB;
    box-sizing: border-box;
    display: flex;
    align-items: center;

    &>div {
      box-sizing: border-box;
      padding-right: 5px;
      word-break: break-all;
    }

    &>div:first-child {
      box-sizing: border-box;
      padding-left: 30px;
      font-size: 16px;
      color: #656775;
      width: 33%;
    }

    &>div:last-child {
      border-left: 1px solid #DCE0EB;
      background: #ffffff;
      padding-left: 30px;
      font-size: 16px;
      color: #333333;
      width: 67%;
    }
  }

  .item-right1 {
    box-sizing: border-box;
    padding: 18px 0;

    span {
      color: #FF8611 !important;
    }
  }

  .item-right {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 18px 0;

    span {
      color: #FF8611 !important;
      font-size: 16px;
    }

    .copy-btn {
      text-align: center;
      display: inline-block;
      width: 40px;
      height: 20px;
      line-height: 20px;
      margin-right: 10px;
      color: #367CC5;
      cursor: pointer;
    }

    .copy-btn:hover,
    .copy-btn:active {
      background: rgba(1, 94, 224, .06);
    }
  }
}

.item-label {
  width: 395px;
}

.right-container {
  width: 60%;
  background: #FAFAFB;
  box-sizing: border-box;
  padding: 11px;

  .message-container,
  .shadow-container {
    background: #FFFFFF;
    border-radius: 8px;
  }

  // .message-container {
  //   max-height: 40vh;
  // }

  .shadow-container {
    margin-top: 12px;
  }
}

.item-title-right {
  box-sizing: border-box;
  padding: 24px;
  display: flex;
  align-items: center;
  font-size: 20px;
  color: #333333;
  font-weight: bold;
  justify-content: space-between;

  &>div {
    display: flex;
    align-items: center;
  }

  img {
    width: 18px;
    height: 20px;
    margin-right: 12px;
  }
}

.device-control {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;


  .control-container {
    width: 40%;
    height: 76vh;
    display: flex;
    flex-direction: column;
    border-right: 2px solid #FAFAFB;
  }


  .item-title {
    box-sizing: border-box;
    padding: 30px 50px;
    display: flex;
    align-items: center;
    font-size: 20px;
    color: #333333;
    font-weight: bold;

    img {
      width: 18px;
      height: 20px;
      margin-right: 12px;
    }
  }

  .search-container {
    box-sizing: border-box;
    padding: 10px 50px;
    display: flex;
    align-items: center;

    .form-label {
      width: 140px;
    }
  }

  .cmd-container {
    box-sizing: border-box;
    padding: 0 50px;
    flex: 1;
  }
}

.btn-groups {
  width: 100%;
  font-size: 16px;
  height: 36px;
  background: #367CC5;
  text-align: center;
  line-height: 36px;
  color: #FFFFFF;
}

.tables {
  margin-top: 20px;
}

:deep(.el-table th.el-table__cell) {
  background-color: #F6F7F9;
  height: 50px;
  font-weight: normal;
  color: #000000;
}

:deep(.el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell) {
  background-color: #FBFBFB;
}

:deep(.el-table td.el-table__cell div) {
  font-size: 16px;
  line-height: 50px;
}
</style>

<style lang="less">
.label-feather {
  .el-form-item__label {
    width: 140px;
  }
}
</style>