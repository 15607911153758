<!--
 * @Description: 设备管理 - 子设备管理
 * @Author: kecraft
 * @Date: 2024-01-15 10:24:57
 * @LastEditors: kecraft
 * @LastEditTime: 2024-05-28 10:07:24
 * @FilePath: /impact-iotos-console/src/views/device/device/detail/topo/topoList.vue
-->
<template>
  <div class="topo-list">
    <div class="btns-container">
      <div class="search-btn" @click="search">
        刷新
      </div>
      <div class="btn-green" @click="() => isAdd = true">
        绑定子设备
      </div>
      <div class="btn-green" @click="() => isBatchAdd = true">
        批量添加子设备
      </div>
    </div>

    <div class="tables">
      <el-table stripe :data="topoList" empty-text="暂无数据" v-loading="isload" style="width: 100%;">
        <el-table-column width="80" label="序号" align="center">
          <template #default="scope">
            <span>{{
        scope.$index + 1
      }}</span>
          </template>
        </el-table-column>
        <el-table-column max-width="160" label="设备名称" prop="name" align="center" show-overflow-tooltip />
        <el-table-column min-width="120" label="设备ID" prop="devId" align="center" show-overflow-tooltip />
        <el-table-column min-width="120" label="所属产品" prop="productName" align="center" show-overflow-tooltip />
        <el-table-column width="160" label="节点类型" prop="deviceTypeStr" align="center" />
        <el-table-column width="170" label="设备状态" prop="online" align="center">
          <template #default="scope">
            <div class="status-container" v-if="!scope.row.online">
              <div class="icon"></div>
              <div>离线</div>
            </div>
            <div class="status-container" v-else>
              <div class="icon1"></div>
              <div>在线</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column width="160" label="是否启用" prop="enable" align="center">
          <template #default="scope">
            <div class="status-container">
              <el-switch v-model="scope.row.enable" class="ml-2"
                style="--el-switch-on-color: #13ce66;margin-right:10px;" :disabled="true" />
              {{ scope.row.enable ? "已启用" : "已禁用" }}
            </div>
          </template>
        </el-table-column>
        <el-table-column min-width="160" label="最后上线时间" prop="loginTime" align="center" />
        <el-table-column width="200" label="操作" align="center">
          <template #default="scope">
            <div class="column-btn">
              <div class="options">
                <span link style="color:#015ee0;cursor: pointer;padding: 0 6px;" @click="handleDetail(scope.row)">
                  查看
                </span>
                <span link style="color:#fc2b2b;cursor: pointer;padding: 0 6px;" @click="handleDel(scope.row)">
                  解绑
                </span>
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="footer-conatiner">
      <pagination :total="total" @pageChange="getList" class="pagination" />
    </div>
    <topoAdd v-if="isAdd" @close="handleAddClose" />
    <topoBatchAdd v-if="isBatchAdd" @close="handleBatchAddClose" />
  </div>
</template>

<script setup>
import pagination from "@/components/control/pagination";
import topoAdd from './topoAdd';
import topoBatchAdd from './topoBatchAdd';
import { reactive, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { ElMessage, ElMessageBox } from "element-plus";
import { deviceTypeList } from "@/hooks/deviceEnum";
import { enumFilter } from "@/hooks/common";
import api from '@/api/api';
const route = useRoute();
const router = useRouter();
const topoList = ref([{}]);
const query = reactive({
  page: 0,
  size: 10,
})
const isAdd = ref(false);
const isload = ref(false);
const isBatchAdd = ref(false);
const total = ref(0);
const search = () => {
  const { pk, devId } = route.query
  if (!pk || !devId) return;
  isload.value = true;
  api.getDevicetTopo({
    page: query.page,
    size: query.size,
    pk,
    devId
  }).then(res => {
    if (res.code === "0") {
      const { totalElements, content } = res.res.data;
      total.value = totalElements;
      content.forEach(item => {
        item.deviceTypeStr = enumFilter(item.deviceType, deviceTypeList)
      })
      topoList.value = content;
      isload.value = false;
    }
  })
}
search();
const handleDetail = (row) => {
  const { pk, devId } = row
  sessionStorage.removeItem("handleDeviceTabsSelect");
  router.replace({
    path: "/product/device/details",
    query: { pk, devId }
  })
}
const handleDel = (row) => {
  ElMessageBox.confirm("确定解绑该子设备吗？", "提示", {
    type: "warning",
    confirmButtonText: "确定",
    cancelButtonText: "取消",
  }).then(() => {
    const { pk, devId } = route.query
    api.delDeviceTopo(pk, devId, {
      pk: row.pk,
      devId: row.devId
    }).then(({ code }) => {
      if (code == "0") {
        ElMessage.success("解绑成功!")
        search()
      }
    })
  })
}
const getList = (v) => {
  query.page = v.pageNum - 1
  query.size = v.pageSize
  search();
}
const handleAddClose = (v) => {
  isAdd.value = false;
  if (v) {
    search()
  }
}
const handleBatchAddClose = (v) => {
  isBatchAdd.value = false;
  if (v) {
    search()
  }
}
</script>
<style lang="less" scoped>
.topo-list {
  box-sizing: border-box;
  padding: 30px 50px;
}

.tables {
  margin-top: 20px;
}

.btns-container {
  display: flex;
  align-items: center;

  .btn-normal {
    margin-right: 10px;
  }
}

.status-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  position: relative;
  border: 1px solid rgba(135, 140, 170, .5);
  box-sizing: border-box;
  margin-right: 10px;
  box-shadow: inset 0 0 4px 0 rgba(135, 140, 170, .5);
}

.icon::before {
  position: absolute;
  left: 4px;
  top: 4px;
  content: "";
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: rgba(135, 140, 170, .5);
}

.icon1 {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  position: relative;
  border: 1px solid #0eb463;
  box-sizing: border-box;
  margin-right: 10px;
  box-shadow: inset 0 0 4px 0 rgba(14, 180, 99, .5);
}

.icon1::before {
  position: absolute;
  left: 4px;
  top: 4px;
  content: "";
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #0eb463;
}

.footer-conatiner {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}


:deep(.el-table th.el-table__cell) {
  background-color: #F6F7F9;
  height: 50px;
  font-weight: normal;
  color: #000000;
}

:deep(.el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell) {
  background-color: #FBFBFB;
}

:deep(.el-table td.el-table__cell div) {
  font-size: 16px;
  line-height: 50px;
}

.btn-normal :deep(.el-button) {
  font-size: 14px;
  height: 36px;
  background: #015ee0;
}

.btn-normal :deep(.el-button:hover) {
  font-size: 14px;
  height: 36px;
  background: #337ecc;
}


.btn-normal :deep(.el-button:active) {
  font-size: 14px;
  height: 36px;
  background: #337ecc;
}

.search-btn {
  cursor: pointer;
  width: 92px;
  height: 36px;
  justify-content: center;
  display: flex;
  align-items: center;
  border-radius: 4px;
  box-sizing: border-box;
  background: #367CC5;
  font-weight: lighter;
  // border: 1px solid #367CC5;
  font-size: 14px;
  color: #FFFFFF;
  font-size: 16px;
}

.search-btn:hover,
.search-btn:active {
  border: 1px solid #367CC5;
  background: #61A4E9;
  // color: #367CC5;
}


.btn-green {
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 140px;
  height: 36px;
  border-radius: 4px;
  background: #30AE7D;
  color: #FFFFFF;
  font-size: 16px;
  font-weight: lighter;
  cursor: pointer;

  &>img {
    width: 18px;
    height: 18px;
    margin-right: 4px;
  }
}
</style>