<!--
 * @Description: 设备管理-上下行数据-数据追踪
 * @Author: kecraft
 * @Date: 2024-01-11 09:52:48
 * @LastEditors: kecraft
 * @LastEditTime: 2024-05-28 14:43:52
 * @FilePath: /impact-iotos-console/src/views/device/device/detail/logs/dataTracking.vue
-->
<template>
  <div class="data-tracking">
    <myDialog @close="handleClose" @ok="handleOk" title="数据追踪" width="700px">
      <div class="width700">
        <div v-if="info && info.traceId" class="traceld-container">{{ `traceId:${info.traceId}` }}
          <div class="copy-btn" @click="copyToClipboard(info.traceId)">复制</div>
        </div>
        <div class="height400">
          <el-scrollbar style="height: 100%" wrap-style="overflow-x:hidden;">
            <div v-for="(item, index) of showList" :key="index" class="track-item">
              <div class="item-title2">
                <div class="item-point"></div>
                <div class="item-name">
                  {{ item.label }}
                </div>
              </div>
              <div class="item-label1">
                <div class="item-line" :class="index !== showList.length - 1 ? 'show-line ' : ''">
                  {{ info[item.key] }}
                </div>
              </div>
            </div>
          </el-scrollbar>
        </div>
      </div>
    </myDialog>
  </div>
</template>

<script setup>
import myDialog from "@/components/dialog/myDialog.vue";
import { defineExpose, ref, defineEmits } from "vue";
import { copyToClipboard } from "@/hooks/common";
const emit = defineEmits(["close"]);
const info = ref({});
const title = ref("数据追踪")
const upList = [
  {
    label: "设备上报",
    key: "rawRequest",
  },
  {
    label: "DLink解析",
    key: "request",
  },
  {
    label: "服务端响应",
    key: "response",
  },
  {
    label: "服务端下发至设备",
    key: "response",
  },
]

const downListSuccess = [
  {
    label: "应用下发请求数据",
    key: "rawRequest",
  },
  {
    label: "服务端响应",
    key: "response",
  },
  {
    label: "服务端下发至设备",
    key: "rawRequest",
  }
]

const downListError = [
  {
    label: "应用下发请求数据",
    key: "rawRequest",
  },
  {
    label: "服务端响应",
    key: "response",
  },
]
const showList = ref([]);
const init = (row) => {
  const oldItem = JSON.parse(JSON.stringify(row));
  const { frameType, isSuccess } = oldItem
  if (frameType === "DEV_UP") {
    showList.value = upList;
  } else {
    if (isSuccess) {
      showList.value = downListSuccess
    } else {
      showList.value = downListError
    }
  }
  info.value = oldItem;
  if (oldItem.traceId) {
    title.value = `数据追踪`
  }

}
const handleClose = () => {
  emit("close")
}
const handleOk = () => {
  emit("close")
}

defineExpose({ init });
</script>

<style lang="less" scoped>
.height400 {
  height: 400px;
}

.width700 {
  width: 700px;
}

.track-item {

  // margin-bottom: 10px;
  .item-title2 {
    display: flex;
    align-items: center;
    line-height: 10px;

    .item-point {
      width: 12px;
      height: 12px;
      margin-right: 10px;
      background: #367CC5;
      border-radius: 50%;
    }

    .item-name {
      margin-left: 15px;
    }
  }

  .item-label1 {
    display: flex;
    align-items: center;

    .item-line {
      box-sizing: border-box;
      color: #656775;
      font-weight: lighter;
      padding: 10px 30px;
      padding-right: 0;
      padding-bottom: 30px;
      margin-left: 5px;
      word-break: break-all;
    }

    .show-line {
      border-left: 2px solid #367CC5;
    }
  }
}

.traceld-container {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
}

.copy-btn {
  flex: 1;
  display: inline-block;
  width: 60px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  margin-left: 40px;
  color: #015ee0;
  cursor: pointer;
}

.copy-btn:hover,
.copy-btn:active {
  background: rgba(1, 94, 224, .06);
}
</style>