<!--
 * @Description: 设备管理-设备影子
 * @Author: kecraft
 * @Date: 2024-01-10 16:14:57
 * @LastEditors: kecraft
 * @LastEditTime: 2024-06-07 11:31:24
 * @FilePath: /impact-iotos-console/src/views/device/device/detail/shadow/deviceShadow.vue
-->
<template>
  <div class="device-shadow">
    <div class="btn-normal">
      <div class="search-btn" @click="getProtocolModel">
        刷新
      </div>
      <!-- <el-button type="primary" :icon="Refresh" class="add-btn" >刷新</el-button> -->
      <div>
        <div class="status-container" v-if="!isOnline">
          <div class="icon"></div>
          <div>离线</div>
        </div>
        <div class="status-container" v-else>
          <div class="icon1"></div>
          <div>在线</div>
        </div>
      </div>
    </div>
    <div class="tables">
      <el-table stripe :data="shadowList" empty-text="暂无数据" v-loading="isload" style="width: 100%;">
        <el-table-column width="80" label="序号" align="center">
          <template #default="scope">
            <span>{{
        scope.$index + 1
              }}</span>
          </template>
        </el-table-column>
        <el-table-column min-width="120" label="参数名称" prop="name" align="center" show-overflow-tooltip />
        <el-table-column width="200" label="参数类型" prop="dataType" align="left" />
        <el-table-column width="200" label="标识符" prop="param" align="left" />
        <el-table-column min-width="200" label="状态值" prop="value" align="center" />
        <el-table-column width="120" label="单位" prop="unit" align="center" />
        <el-table-column min-width="160" label="最后一次上报时间" prop="lastTime" align="center" />
      </el-table>
    </div>
  </div>
</template>

<script setup>
import moment from "moment";
import { ref, onMounted, onBeforeUnmount } from 'vue';
import { useRoute } from 'vue-router';
import api from '@/api/api';
import ws from "@/utils/webSocket";
import { javaWs } from "@/utils/config";
const isOnline = ref(false);
const route = useRoute();
onMounted(() => {
  const { pk, devId } = route.query;
  if (!pk && devId) return;
  getProtocolModel()
  initWebSocket();
})
const paramsList = ref([]); // 展示的params
const shadowList = ref([]);
const isload = ref(false);
const getDeviceInfo = () => {
  const { pk, devId } = route.query;
  api.getDeviceInfo(pk, devId).then(res => {
    if (res.code === "0") {
      const { online } = res.res.data;
      isOnline.value = online;
    }
  })
}
const initWebSocket = () => {
  const { pk, devId } = route.query;
  const token_url = process.env.VUE_APP_TOKEN
  let tokenstr = localStorage.getItem(token_url) || ""
  if (tokenstr) {
    tokenstr = tokenstr.split(" ")[1]
  }
  const url = javaWs + `?CLIENT_IP=127.0.0.1&CLIENT_ID=device:${pk},${devId}&SUBSCRIBE=device_snapshot,device_online&TOKEN=${tokenstr}`;
  ws.initWebSocket(url);
  ws.sendSock("PING", wsMessage);
}
const wsMessage = (e) => {
  if (e && e.data) {
    var obj = JSON.parse(e.data);
    if (obj && obj.type === "device_snapshot") {
      dealResult(obj.data);
    } else if (obj && obj.type === "device_online") {
      isOnline.value = obj.data;
    }
  }
}
/**
 * 根据pk获取功能定义
 * @param {*} pk 
 */
const getProtocolModel = () => {
  getDeviceInfo();
  const { pk } = route.query
  isload.value = true;
  api.protocolModel(pk).then(res => {
    if (res.code === "0") {
      const { params } = res.res.data;
      paramsList.value = params;
      search();
    }
  })
}
const search = () => {
  const { pk, devId } = route.query;
  if (!pk || !devId) return;
  api.getSnapshot({ pk, devId }).then(res => {
    if (res.code === "0" && res.res.data.data) {
      dealResult(res.res.data, 1);
      isload.value = false;
    } else {
      isload.value = false;
    }
  })
}

const dealResult = (data, type) => {
  const { params } = data.data;
  const time = data.timestamp
  let lastTime = "--"
  if (time) {
    lastTime = moment(time).format('YYYY-MM-DD HH:mm:ss')
  }
  const list = paramsList.value
  for (let i in params) {
    list.forEach(item => {
      if (item.checkType.type === "ENUM") {
        // 如果是枚举 则需要去取 desc
        const enums = item.checkType.values;
        enums.forEach(a => {
          if (a.value === params[i]) {
            if (type === 1) {
              if (i == item.param) {
                item.value = a.value + ":" + a.desc;
              }
              item.lastTime = lastTime;
            } else {
              if (i == item.param) {
                item.value = a.value + ":" + a.desc;
                item.lastTime = lastTime;
              }
            }
          }
        })
      } else {
        if (type === 1) {
          if (i == item.param) {
            item.value = params[i]
          }
          item.lastTime = lastTime;
        } else {
          if (i == item.param) {
            item.value = params[i]
            item.lastTime = lastTime;
          }
        }
      }

    })
  }
  shadowList.value = list;
}
onBeforeUnmount(() => {
  if (ws) {
    ws.websocketclose();
    console.log("关闭连接");
  }
})
</script>

<style lang="less" scoped>
.search-btn {
  cursor: pointer;
  width: 92px;
  height: 36px;
  justify-content: center;
  display: flex;
  align-items: center;
  border-radius: 4px;
  box-sizing: border-box;
  background: #367CC5;
  font-weight: lighter;
  // border: 1px solid #367CC5;
  font-size: 14px;
  color: #FFFFFF;
  font-size: 16px;
}

.search-btn:hover,
.search-btn:active {
  border: 1px solid #367CC5;
  background: #61A4E9;
  // color: #367CC5;
}

.device-shadow {
  box-sizing: border-box;
  padding: 30px 50px;
}

.tables {
  margin-top: 20px;
}

:deep(.el-table th.el-table__cell) {
  background-color: #F6F7F9;
  height: 50px;
  font-weight: normal;
  color: #000000;
}

:deep(.el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell) {
  background-color: #FBFBFB;
}

:deep(.el-table td.el-table__cell div) {
  font-size: 16px;
  line-height: 50px;
}

.btn-normal {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.btn-normal :deep(.el-button) {
  font-size: 14px;
  height: 36px;
  background: #015ee0;
}

.btn-normal :deep(.el-button:hover) {
  font-size: 14px;
  height: 36px;
  background: #337ecc;
}


.btn-normal :deep(.el-button:active) {
  font-size: 14px;
  height: 36px;
  background: #337ecc;
}

.status-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  position: relative;
  border: 1px solid rgba(135, 140, 170, .5);
  box-sizing: border-box;
  margin-right: 10px;
  box-shadow: inset 0 0 4px 0 rgba(135, 140, 170, .5);
}

.icon::before {
  position: absolute;
  left: 4px;
  top: 4px;
  content: "";
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: rgba(135, 140, 170, .5);
}

.icon1 {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  position: relative;
  border: 1px solid #0eb463;
  box-sizing: border-box;
  margin-right: 10px;
  box-shadow: inset 0 0 4px 0 rgba(14, 180, 99, .5);
}

.icon1::before {
  position: absolute;
  left: 4px;
  top: 4px;
  content: "";
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #0eb463;
}
</style>