<!--
 * @Description: 设备管理 - 指标趋势
 * @Author: kecraft
 * @Date: 2024-01-11 09:17:10
 * @LastEditors: kecraft
 * @LastEditTime: 2024-05-28 14:24:31
 * @FilePath: /impact-iotos-console/src/views/device/device/detail/indicatorTrend/indicatorTrend.vue
-->
<template>
  <div class="indicator-trend">
    <div class="search-conatiner">
      <div class="form-item">
        <span>参数：</span>
        <el-select v-model="query.param" placeholder="请选择参数" class="width180" @change="search">
          <el-option v-for="item in paramsList" :key="item.param" :label="item.name" :value="item.param" />
        </el-select>
      </div>
      <div class="form-item">
        <span>选择时间段：</span>
        <el-select v-model="query.dateType" placeholder="请选择" class="width180" @change="handleDateChange" clearable>
          <el-option v-for="item in dateTypeList" :key="item.key" :label="item.label" :value="item.key" />
        </el-select>
      </div>
      <div class="form-item">
        <span>选择时间：</span>
        <el-date-picker v-model="query.time" type="datetimerange" range-separator="至" start-placeholder="开始时间"
          end-placeholder="结束时间" />
      </div>
      <div class="search-btn" @click="search" v-preventReClick>搜索</div>
      <div class="form-item" style="flex:1">
        <div class="reset-btn" @click="reset" v-preventReClick>重置</div>
      </div>
      <div class="form-item" style="margin-right: 0;">
        <div @click="uploadParam" v-preventReClick class="upload-btn">
          <img src="@/assets/menu/upload.png" alt="">
          <span>导出</span>
        </div>
      </div>
    </div>
    <div class="title-box">
      <img src="@/assets/trand.png">
      指标趋势统计图
    </div>
    <div id="chart-container"></div>
  </div>
</template>

<script setup>
let myChart = null;
import FileSaver from 'file-saver'
import moment from "moment";
import { ElMessage } from "element-plus";
import * as echarts from "echarts";
import { onMounted, reactive, ref, onBeforeUnmount } from 'vue';
import api from '@/api/api';
import { useRoute } from 'vue-router';
const route = useRoute();
const allData = ref([]);
const query = reactive({
  param: "",
  time: "",
  dateType: "DAY"
})
const paramsList = ref([]);
const reset = () => {
  query.param = "";
  const endTime = (new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1)
  const end = new Date(endTime)
  const startTime = new Date(new Date().toLocaleDateString()).getTime() - 24 * 60 * 60 * 1000
  const start = new Date(startTime)
  query.time = [start, end]
  query.dateType = "DAY";
  search()
}

const dateTypeList = ref([
  {
    key: "HOUR",
    label: "近一小时"
  },
  {
    key: "DAY",
    label: "近一天"
  },
  {
    key: "WEEK",
    label: "近一周"
  }
])
onMounted(() => {
  const { pk } = route.query
  api.protocolModel(pk).then(res => {
    if (res.code === "0") {
      const list = []
      const { params } = res.res.data;
      params.forEach(item => {
        if (item.dataType !== "STRING") {
          list.push(item)
        }
      })
      paramsList.value = list;
      const endTime = (new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1)
      const end = new Date(endTime)
      const startTime = new Date(new Date().toLocaleDateString()).getTime() - 24 * 60 * 60 * 1000
      const start = new Date(startTime)
      query.time = [start, end]
      if (list.length > 0) {
        query.param = list[0].param
        search();
      }
    }
  })
})

const handleDateChange = () => {
  if (query.dateType === "DAY") {
    const endTime = (new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1)
    const end = new Date(endTime)
    const startTime = new Date(new Date().toLocaleDateString()).getTime() - 24 * 60 * 60 * 1000
    const start = new Date(startTime)
    query.time = [start, end]
  } else if (query.dateType === "HOUR") {
    const endTime = new Date().getTime()
    const end = new Date(endTime)
    const startTime = new Date().getTime() - 60 * 60 * 1000
    const start = new Date(startTime)
    query.time = [start, end]
  } else if (query.dateType === "WEEK") {
    const endTime = (new Date(new Date().toLocaleDateString()).getTime() + 24 * 60 * 60 * 1000 - 1)
    const end = new Date(endTime)
    const startTime = new Date(new Date().toLocaleDateString()).getTime() - 7 * 24 * 60 * 60 * 1000
    const start = new Date(startTime)
    query.time = [start, end]
  }
}
const search = () => {
  const { pk, devId } = route.query;
  if (!query.param || !query.time) return;
  const timeList = query.time;
  const startTime = timeList[0].getTime();
  const endTime = timeList[1].getTime();
  allData.value = []
  api.getDeviceKvlog(pk, devId, {
    startTime,
    endTime,
    param: query.param
  }).then(res => {
    if (res.code === "0") {
      const xAxisList = [];
      const dataList = [];
      const list = res.res.data;
      allData.value = list;
      list.forEach(item => {
        dataList.push(item.value)
        const time = moment(item.timestamp).format('YYYY-MM-DD HH:mm:ss')
        xAxisList.push(time);
      })
      if (dataList.length > 0 && xAxisList.length > 0) {
        initChart(xAxisList, dataList)
      } else {
        if (myChart) {
          myChart.clear();
        }
      }
    }
  })
}
const initChart = (xAxisList, dataList) => {
  if (!myChart) {
    myChart = echarts.init(document.getElementById('chart-container'));
  }
  var option = {
    tooltip: {
      show: true,
    },
    grid: {
      left: "5%",
      right: "5%",
      bottom: '15%',
      top: "5%",
    },
    xAxis: {
      data: xAxisList
    },
    dataZoom: [
      {
        type: 'slider', //滑动条型数据区域缩放组件
        realtime: true, //拖动时，是否实时更新系列的视图。如果设置为 false，则只在拖拽结束的时候更新。
        // start: 0, //数据窗口范围的起始百分比。范围是：0 ~ 100。表示 0% ~ 100%。
        // end: 10,  // 数据窗口范围的结束百分比。范围是：0 ~ 100
        height: 4,
        endValue: 49,  //数据窗口范围的结束数值。如果设置了 dataZoom-inside.end 则 endValue 失效
        fillerColor: "rgba(17, 100, 210, 0.4)", // 滚动条颜色
        borderColor: "rgba(17, 100, 210, 0.1)",
        handleSize: 0, // 两边手柄尺寸
        showDetail: false, // 拖拽时是否展示滚动条两侧的文字
        top: '96%', //组件离容器上侧的距离
        zoomLock: true, // 是否只平移不缩放
        // moveOnMouseMove:true, //开启鼠标移动窗口平移
        // zoomOnMouseWheel :true, //开启鼠标滚轮缩放
      },
      {
        type: "inside",  //内置型数据区域缩放组件
        // start: 0,
        // end: 10,
        endValue: 49,  // 最多12个
        zoomOnMouseWheel: false,  // 关闭鼠标滚轮缩放
        moveOnMouseWheel: true, // 开启鼠标滚轮窗口平移
        moveOnMouseMove: true  // 开启鼠标移动窗口平移
      }
    ],
    yAxis: {},
    series: [{
      showAllSymbol: true,
      // name: '销量',
      type: 'line',
      data: dataList
    }]
  }
  myChart.setOption(option);
  window.addEventListener("resize", function () {
    myChart.resize();
  });
}
onBeforeUnmount(() => {
  if (myChart) {
    myChart.dispose();
  }
})

const uploadParam = () => {
  if (allData.value.length === 0) return;
  const json = allData.value;
  const blob = new Blob([JSON.stringify(json)], { type: "application/json;charset=utf-8" });
  FileSaver.saveAs(blob, query.param + '.json')
  ElMessage.success("导出成功!")
}
</script>

<style lang="less" scoped>
.title-box {
  display: flex;
  align-items: center;
  margin-top: 50px;
  font-size: 20px;
  color: #333333;
  font-weight: bold;

  img {
    width: 22px;
    height: 22px;
    margin-right: 10px;
  }
}

.width180 {
  width: 180px;
}

.upload-btn {
  cursor: pointer;
  width: 92px;
  height: 36px;
  justify-content: center;
  display: flex;
  align-items: center;
  border-radius: 4px;
  box-sizing: border-box;
  font-weight: lighter;
  border: 1px solid #AFB9CB;
  color: #333333;
  font-size: 16px;

  img {
    width: 16px;
    height: 16px;
    margin-right: 10px;
  }
}

.reset-btn {
  cursor: pointer;
  width: 92px;
  height: 36px;
  justify-content: center;
  display: flex;
  align-items: center;
  border-radius: 4px;
  box-sizing: border-box;
  font-weight: lighter;
  border: 1px solid #AFB9CB;
  color: #333333;
  font-size: 16px;
}

.search-btn {
  margin-right: 10px;
  cursor: pointer;
  width: 92px;
  height: 36px;
  text-align: center;
  line-height: 36px;
  box-sizing: border-box;
  background: #367CC5;
  border-radius: 4px;
  font-size: 14px;
  color: #FFFFFF;
}

.search-btn:hover,
.search-btn:active {
  background: #015ee0;
  color: #fff;
}

.search-conatiner {
  display: flex;
  align-items: center;

  .form-item {
    margin-right: 30px;
    display: flex;
    align-items: center;

    span {
      color: #606266;
    }
  }
}

.indicator-trend {
  box-sizing: border-box;
  padding: 30px 50px;
}

.search-container {
  box-sizing: border-box;
  padding: 0 20px;
  box-shadow: 0 5px 10px 5px #6666661a;
}

.search-top {
  display: flex;
  align-items: center;
  color: rgb(123, 132, 140);
  box-sizing: border-box;
  padding: 20px 0;
  border-bottom: 1px dashed rgb(227, 227, 227);

  &>div {
    font-size: 14px;
  }
}

.no-data {
  text-align: center;
  z-index: 10000;
  position: absolute;
  left: 45%;
  top: 20vh;
}

.search-bottom {
  display: flex;
  align-items: center;
  color: rgb(123, 132, 140);
  box-sizing: border-box;
  padding: 20px 0;

  &>div {
    font-size: 14px;
  }

  .btn-groups {
    margin-left: 20px;
    flex: 1;
    display: flex;
    align-items: center;

    &>div {
      margin-right: 20px;
    }
  }
}

#chart-container {
  width: 100%;
  height: 320px;
  text-align: center;
  box-sizing: border-box;
}

.chart-container {
  position: relative;
  margin-top: 20px;
  box-sizing: border-box;
  height: 55vh;
  box-shadow: 0 5px 10px 5px #6666661a;
  padding: 0 20px;

  .chart-top {

    font-weight: bold;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 20px 0;
    border-bottom: 1px dashed rgb(227, 227, 227);

    &>img {
      width: 24px;
      margin-right: 10px;
      margin-left: 10px;
    }

    &>div {
      font-size: 20px;
    }
  }
}

.btn-plain :deep(.el-button) {
  font-size: 14px;
  height: 36px;
}

.btn-plain :deep(.el-button:hover) {
  color: #0155ca;
  border-color: #0155ca;
  background: #e6effc;
}


.btn-plain :deep(.el-button:active) {
  color: #0155ca;
  border-color: #0155ca;
  background: #e6effc;
}

.btn-normal :deep(.el-button) {
  font-size: 14px;
  height: 36px;
  background: #015ee0;
}

.btn-normal :deep(.el-button:hover) {
  font-size: 14px;
  height: 36px;
  background: #337ecc;
}


.btn-normal :deep(.el-button:active) {
  font-size: 14px;
  height: 36px;
  background: #337ecc;
}
</style>