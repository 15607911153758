<!--
 * @Description: 子设备管理 - 批量添加子设备
 * @Author: kecraft
 * @Date: 2024-01-15 10:58:27
 * @LastEditors: kecraft
 * @LastEditTime: 2024-06-07 09:53:04
 * @FilePath: /impact-iotos-console/src/views/device/device/detail/topo/topoBatchAdd.vue
-->
<template>
  <div class="topo-batch-add">
    <myDialog @close="handleClose" @ok="handleOk" title="批量添加子设备">
      <div class="width700">
        <el-form ref="formBatchDeviceAdd" :model="form" label-position="left">
          <el-form-item label="添加方式：" prop="type" class="label-feather">
            <el-radio-group v-model="form.type">
              <el-radio label="0">增量</el-radio>
              <el-radio label="1">全量</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="文件上传：" class="label-feather">
            <div class="btn-normal">
              <div class="upload-box">
                <el-upload action="" class="upload-demo" ref="upload" accept=".csv" :limit="1" :show-file-list="false"
                  :on-exceed="handleExceed" :http-request="changeUpload">
                  <template #trigger>
                    <div class="btn-upload">
                      <img src="@/assets/menu/upload.png">点击上传
                    </div>
                  </template>
                </el-upload>
                <div class="download-btn" @click="downloadFile">下载模板</div>
              </div>
              <!-- 上传中 -->
              <div class="file-container" v-if="isUploading">
                <img src="@/assets/drive/file.png">
                <div>{{ isUploading }}</div>
                <img src="@/assets/drive/close.png" class="is-close" @click="handleClear">
              </div>
            </div>
          </el-form-item>
        </el-form>
        <div class="info-box">
          <p>最大支持批量添加1000个。</p>
          <p>增量：保留已有的子设备并导入子设备。</p>
          <p>全量：清除已有的子设备并导入子设备。</p>
          <p>提示：导入工作将在后台进行，根据导入数量（最大1000，最少1个）需要3s-1分钟时间，导入工作结束后，结果可以在设备上下行记录（batchAddTopo事件）中查看。</p>
        </div>
      </div>
    </myDialog>
  </div>
</template>

<script setup>
import myDialog from "@/components/dialog/myDialog.vue";
import { reactive, ref, defineEmits, onMounted, getCurrentInstance } from "vue";
import { ElMessage } from "element-plus";
import api from '@/api/api';
import { useRoute } from "vue-router";
const route = useRoute();
const emit = defineEmits(["close"]);
let currentInstance = "";
const isUploading = ref("");
onMounted(() => {
  currentInstance = getCurrentInstance();
})
const form = reactive({
  type: "0",// 0:增量 1:全量
});
const fileValue = ref(null);
const changeUpload = (e) => {
  const file = e.file
  const size = file.size / (1024 * 1024); //文件大小
  if (size > 50) {
    return ElMessage.error("文件大小不能超过50M！");
  }
  let fd = new FormData();
  fd.append("file", file);
  fileValue.value = fd;
  isUploading.value = file.name
}
const downloadFile = () => {
  let a = document.createElement("a");
  a.href = "/static/demo1.csv";
  a.download = "模板.csv"; //设置下载文件文件名，这里加上.pdf指定文件类型，word文件就指定.word即可
  a.style.display = "none"; // 障眼法藏起来a标签
  document.body.appendChild(a); // 将a标签追加到文档对象中
  a.click(); // 模拟点击了a标签，会触发a标签的href的读取，浏览器就会自动下载了
  a.remove();
}
const handleExceed = (e) => {
  currentInstance.proxy.$refs.upload.clearFiles();
  currentInstance.proxy.$refs.upload.handleStart(e[0]);
  currentInstance.proxy.$refs.upload.submit();
  fileValue.value = ""
}
const handleOk = () => {
  if (!fileValue.value) {
    ElMessage.error("请选择上传的文件")
    return;
  }
  const { pk, devId } = route.query
  const overwrite = form.type === '0' ? false : true
  api.batchAddDeviceTopoByFile(pk, devId, fileValue.value, {
    overwrite,
  }).then(res => {
    if (res.code === "0") {
      ElMessage.success("添加成功！")
      emit("close", true)
    }
  })
}
const handleClose = () => {
  emit("close", false)
}
const handleClear = () => {
  fileValue.value = "";
  isUploading.value = "";
}

</script>

<style lang="less" scoped>
.width700 {
  width: 700px;
}

.btn-normal {

  .download-btn {
    cursor: pointer;
    margin-left: 20px;
    color: -webkit-link;
    text-decoration: underline;
  }
}



.info-box {
  margin-top: 20px;
  font-size: 14px;
  color: #5E6B86;
  background: #DDE6F3;
  box-sizing: border-box;
  padding: 12px 25px;
  border-radius: 4px;

  p {
    line-height: 20px;
  }
}


.upload-box {
  display: flex;
  align-items: center;

  .btn-upload {
    width: 136px;
    height: 40px;
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid #B7BBC0;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 15px;
      height: 15px;
      margin-right: 4px;
    }
  }
}

.file-container {
  display: flex;
  align-items: center;
  margin-top: 10px;

  img {
    width: 16px;
    height: 16px;
    margin-right: 10px;
  }

  .is-close {
    margin-left: 20px;
    width: 14px;
    height: 14px;
  }
}
</style>

<style lang="less">
.label-feather {
  .el-form-item__label {
    width: 100px;
  }
}
</style>