<!--
 * @Description: 连接信息
 * @Author: kecraft
 * @Date: 2024-01-30 14:09:39
 * @LastEditors: kecraft
 * @LastEditTime: 2024-05-29 10:43:55
 * @FilePath: /impact-iotos-console/src/views/device/device/detail/clientInfoDialog.vue
-->
<template>
  <div class="client-info">
    <myDialog @close="handleClose" @ok="handleOk" :title="title" width="800px" okTitle="一键复制">
      <div class="width800">
        <div class="info-item" v-for="(item, index) of infoList" :key="index">
          <div class="item-left">{{ item.key }}</div>
          <div class="item-right">{{ item.value }}</div>
          <div class="item-copy" @click="copyToClipboard(item.value)">复制</div>
        </div>
      </div>
    </myDialog>
  </div>
</template>

<script setup>
import myDialog from "@/components/dialog/myDialog.vue";
import { defineExpose, defineEmits, ref } from 'vue';
import { copyToClipboard } from "@/hooks/common";
const emit = defineEmits(["close"]);
const title = ref(null);
const info = ref(null);
const infoList = ref([]);
const handleOk = () => {
  const json = JSON.stringify(info.value)
  copyToClipboard(json)
}
const handleClose = () => {
  emit("close");
}

const init = (row) => {
  if (!row) return;
  const oldForm = JSON.parse(JSON.stringify(row));
  let list = [];
  for (let i in oldForm) {
    if (i !== "label") {
      const obj = {
        key: i,
        value: oldForm[i]
      }
      list.push(obj)
    }
  }
  const { label } = oldForm;
  title.value = label;
  infoList.value = list;
  info.value = oldForm
}
defineExpose({ init });
</script>

<style lang="less" scoped>
.width800{
  width: 800px;
}
.info-item {
  display: flex;
  margin-bottom: 20px;
  align-items: center;

  .item-left {
    width: 120px;
    font-size: 14px;
  }

  .item-right {
    font-size: 14px;
    line-height: 22px;
    flex: 1;
  }

  .item-copy {
    width: 40px;
    font-size: 14px;
    margin-left: 20px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    color: #015ee0;
    cursor: pointer;
  }

  .item-copy:hover,
  .item-copy:active {
    background: rgba(1, 94, 224, .06);
  }
}
</style>