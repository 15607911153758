<!--
 * @Description: 设备管理 - 指标聚合
 * @Author: kecraft
 * @Date: 2024-01-11 09:17:10
 * @LastEditors: kecraft
 * @LastEditTime: 2024-01-11 09:43:35
 * @FilePath: /impact-iotos-console/src/views/device/device/detail/indicatorTrend/indicatorPoly.vue
-->
<template>
  <div class="indicator-trend">
    <div class="search-container">
      <div class="search-top">
        <div>参数：</div>
        <el-select v-model="query.param" placeholder="请选择参数" style="width: 180px">
          <el-option v-for="item in paramsList" :key="item.value" :label="item.label" :value="item.value" />
        </el-select>
      </div>
      <div class="search-bottom">
        <div>选择时间：</div>
        <div class="form-item">
          <el-date-picker v-model="query.time" type="datetimerange" range-separator="至" start-placeholder="开始时间"
            end-placeholder="结束时间" />
          <el-select v-model="query.type" placeholder="请选择时间" style="width: 180px;margin-left:20px">
            <el-option v-for="item in timeList" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
        </div>
        <div class="btn-groups">
          <div class="btn-normal">
            <el-button type="primary" :icon="Search" class="add-btn">查询</el-button>
          </div>
          <div class="btn-plain">
            <div class="btn-plain"><el-button plain :icon="Upload">导出</el-button> </div>
          </div>
        </div>
      </div>
    </div>

    <div class="chart-container">
      <div class="chart-top">
        <img src="@/assets/icon/trend.png">
        <div>指标聚合统计图</div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { Search, Upload } from '@element-plus/icons-vue';

import { reactive, ref } from 'vue';

const query = reactive({
  param: "",
  time: "",
  type: "",
})
const timeList = ref([]);
const paramsList = ref([]);
</script>

<style lang="less" scoped>
.indicator-trend {
  box-sizing: border-box;
  padding: 20px 30px;
}

.search-container {
  box-sizing: border-box;
  padding: 0 20px;
  box-shadow: 0 5px 10px 5px #6666661a;
}

.search-top {
  display: flex;
  align-items: center;
  color: rgb(123, 132, 140);
  box-sizing: border-box;
  padding: 20px 0;
  border-bottom: 1px dashed rgb(227, 227, 227);

  &>div {
    font-size: 14px;
  }
}

.search-bottom {
  display: flex;
  align-items: center;
  color: rgb(123, 132, 140);
  box-sizing: border-box;
  padding: 20px 0;

  &>div {
    font-size: 14px;
  }

  .btn-groups {
    margin-left: 20px;
    flex: 1;
    display: flex;
    align-items: center;

    &>div {
      margin-right: 20px;
    }
  }
}

.form-item {
  width: 600px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.chart-container {
  margin-top: 20px;
  box-sizing: border-box;
  height: 55vh;
  box-shadow: 0 5px 10px 5px #6666661a;
  padding: 0 20px;

  .chart-top {

    font-weight: bold;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 20px 0;
    border-bottom: 1px dashed rgb(227, 227, 227);

    &>img {
      width: 24px;
      margin-right: 10px;
      margin-left: 10px;
    }

    &>div {
      font-size: 20px;
    }
  }
}

.btn-plain :deep(.el-button) {
  font-size: 14px;
  height: 36px;
}

.btn-plain :deep(.el-button:hover) {
  color: #0155ca;
  border-color: #0155ca;
  background: #e6effc;
}


.btn-plain :deep(.el-button:active) {
  color: #0155ca;
  border-color: #0155ca;
  background: #e6effc;
}

.btn-normal :deep(.el-button) {
  font-size: 14px;
  height: 36px;
  background: #015ee0;
}

.btn-normal :deep(.el-button:hover) {
  font-size: 14px;
  height: 36px;
  background: #337ecc;
}


.btn-normal :deep(.el-button:active) {
  font-size: 14px;
  height: 36px;
  background: #337ecc;
}
</style>